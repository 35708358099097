<template>
  <div class='add-signature-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='add-signature-title'>Let's add your signature</div>
            <div class='add-signature-stepper'>Step 5</div>

            <div class='add-signature-option-1'>Upload Signature</div>
            <div class='add-signature-upload-btn'>
              <a-upload
                  v-model:file-list='signatureFileList'
                  :max-Count='1'
                  accept='image/png, image/jpeg, image/jpg'
                  :before-upload='beforeUpload'
                  @change='onChangeSignatureImage'
                  :customRequest='onUploadSignature'
                >
                  <a-button :disabled="disable"
                    style='width: 100%; height: 44px; border: 1px solid #79a89b'
                  >
                  <span style="float: left;">
                    Upload File
                  </span>
                    <cloud-upload-outlined style="font-size: 20px;float: right;"/>
                  </a-button>
                </a-upload>
                <!-- <div
                  v-if='ifSelfieUpload'
                  style='color: red; text-align: initial'
                >
                  Please upload File
                </div> -->
            </div>
            <!-- <div class='add-signature-option-2'>Option 2: Sign on Screen</div>
            <div class='add-signature-sign-on-screen'>
              <a-textarea
                class='signature-text-area'
                style='background: #e0e0e0'
                :autosize='false'
                v-model:value='signatureValue'
                placeholder='Sign within the box'
                :rows='4'
              />
            </div> -->
            <div class='add-signature-btn-wrapper'>
              <a-button type='primary' @click='onClickContinue' :loading="load"
                >Proceed</a-button
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    CloudUploadOutlined,
  },
  setup() {
    const store = useStore();
    const signatureValue = ref('');
    const load = ref(false);
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          store.dispatch('onboardingStore/updateClientName', response.data.data.prefill.name);
        })
        .catch(() => {
        });
    });
    const router = useRouter();
    const disable = ref(false);
    const signatureFileList = ref([]);
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        const message = 'Validation error';
        const description = 'Only png, jpg, and jpeg images are accepted';
        Operations.notificationError(message, description);
      }
      return isJpgOrPng;
    };
    // const onChangeSignature = () => {
    //   if (signatureFileList.value.length > 0) {
    //     ifSelfieUpload.value = false;
    //   }
    // };
    const onUploadSignature = ({ file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'SIGNATURE');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      disable.value = true;
      const message = null;
      const description = 'Uploading your signature...';
      Operations.showNotification(message, description);
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          Operations.showNotification(message, description);
          store.dispatch('onboardingStore/updateVerifySignature', uploadResponse.data.url);
          disable.value = false;
        })
        .catch(() => {
          Operations.showNotification(message, description);
          signatureFileList.value = [];
          disable.value = false;
        });
    };
    const onClickContinue = () => {
      if (signatureFileList.value.length === 0) {
        const message = 'Validation error';
        const description = 'Please upload your signature';
        Operations.notificationError(message, description);
      } else {
        load.value = true;
        router.push('/onboarding/signature/verification');
        store.dispatch('onboardingStore/updateCurrentStage', STAGES.STAGE_TYPE.SIGNATURE);
      }
    };

    return {
      onClickContinue,
      signatureValue,
      signatureFileList,
      onUploadSignature,
      // onChangeSignature,
      beforeUpload,
      load,
      disable,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/AddSignature.scss';
</style>
